<template>
  <div>
    <!-- Begin Page Content -->
    <div class="container-fluid">
      <!-- Page Heading -->
      <h1 class="h3 mb-4 text-gray-800">Film Details</h1>
    </div>
    <!-- /.container-fluid -->

    <div>
      <!-- Page Container -->
      <div class="w3-content w3-margin-top" style="max-width: 1400px">
        <!-- The Grid -->
        <div class="w3-row-padding">
          <!-- Left Column -->
          <div class="w3-third">
            <div class="w3-white w3-text-grey w3-card-4">
              <div class="w3-display-container">
                <img :src="film_details ? film_details.thumbnail : ''" style="width: 100%; hieght: 100%" alt="Poster" />
                <div class="w3-display-bottomleft w3-container w3-text-white">
                  <h2>{{ film_details?film_details.film_name: "" }}</h2>
                </div>
              </div>
              <div class="w3-container mt-3">
                <p>
                  <i
                    class="fa fa-globe fa-fw w3-margin-right w3-large w3-text-teal"></i>{{
                    film_details?film_details.website:
                    ""}}
                </p>
                <p>
                  <i
                    class="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-teal"></i>{{
                    film_details?film_details.company:
                    ""}}
                </p>
                <p>
                  <i
                    class="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-teal"></i>{{
                    film_details?film_details.email:
                    ""}}
                </p>
                <p>
                  <i
                    class="fa fa-phone fa-fw w3-margin-right w3-large w3-text-teal"></i>{{
                    film_details?film_details.phone:
                    ""}}
                </p>
                <p>
                  <i
                    class="fa fa-map-marker fa-fw w3-margin-right w3-large w3-text-teal"></i>{{
                    film_details?film_details.address:
                    ""}}
                </p>
                <p>
                  <i class="fa fa-fw w3-margin-right w3-large w3-text-teal"></i>{{ film_details?film_details.city: ""}}
                </p>
                <p>
                  <i class="fa fa-fw w3-margin-right w3-large w3-text-teal"></i>{{ film_details?film_details.state: ""}}
                </p>
                <p>
                  <i class="fa fa-fw w3-margin-right w3-large w3-text-teal"></i>{{
                  film_details?film_details.country:
                  ""}}
                </p>
                <p>
                  <i class="fa fa-fw w3-margin-right w3-large w3-text-teal"></i>{{ film_details?film_details.zip: ""}}
                </p>
                <hr />

                <p class="w3-large">
                  <b><i class="fa fa-hashtag fa-fw w3-margin-right w3-text-teal"></i>Hashtags</b>
                </p>
                <p>Movie</p>
                <br />

                <p class="w3-large">
                  <b><i class="fa fa-envelope fa-fw w3-margin-right w3-text-teal"></i>Message</b>
                </p>
                <p>
                  {{ film_details?film_details.message: ""}}
                </p>
                <br />

                <p class="w3-large">
                  <b><i class="fa fa-file fa-fw w3-margin-right w3-text-teal"></i>Sypnosis</b>
                </p>
                <p>
                  {{ film_details?film_details.synopsis: ""}}
                </p>
                <br />

                <p class="w3-large w3-text-theme">
                  <b><i class="fa fa-globe fa-fw w3-margin-right w3-text-teal"></i>Language(s)</b>
                </p>
                <p>{{ film_details?film_details.language: ""}}</p>
                <br />
              </div>
            </div>
            <br />
            <!-- End Left Column -->
          </div>
          <!-- Right Column -->
          <div class="w3-twothird">
            <div class="w3-container w3-card w3-white w3-margin-bottom">
              <h2 class="w3-text-grey w3-padding-16">
                <i
                  class="fas fa-video-camera fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>{{
    film_details?film_details.film_name
                  : ""}}
              </h2>
              <div class="row">
                <div class="w3-container col-md-6">
                  <h5 class="w3-opacity"><b>Content Relation here</b></h5>
                  <h6 class="w3-text-teal">
                    <i class="fa fa-calendar fa-fw w3-margin-right"></i>{{ film_details?film_details.release_date: ""}} -
                    <span class="w3-tag w3-teal w3-round">Release Date</span>
                  </h6>
                  <h6 class="w3-text-teal">
                    <i class="fa fa-clock fa-fw w3-margin-right"></i>{{
                  film_details?(Math.ceil(Number(
                    film_details.run_time) / 60))+ " Mins": ""}} -
                    <span class="w3-tag w3-teal w3-round">Run Time</span>
                  </h6>
                  <br />
                  <h5 class="w3-opacity"><b>Genre</b></h5>
                  <p>{{ film_details?film_details.genre: ""}}</p>
                  <br />
                  <h5 class="w3-opacity"><b>Director</b></h5>
                  <p>{{ film_details?film_details.director: ""}}</p>
                  <br />
                  <h5 class="w3-opacity"><b>Crew</b></h5>
                  <p>{{ film_details?film_details.cast: ""}}</p>
                  <hr />
                </div>
                <div class="w3-container col-md-6">
                  <h5 class="w3-opacity"><b>Action</b></h5>
                  <button v-if="film_details && film_details.status=='in-active'" type="button" class="approvebtn" @click="updateMovieStatus('active')">Approve</button>

                  <button v-if="film_details && film_details.status=='in-active'" type="button" class="rejectbtn" @click="updateMovieStatus('rejected')">Reject</button>
                  <p v-if="film_details && film_details.status=='active'" class="text-success">Movie is Active</p>
                  <p v-if="film_details && film_details.status=='rejected'" class="text-danger">Movie has been Rejected</p>
                  <br /><br /><br />
                  <h5 class="w3-opacity"><b>Sub Genre</b></h5>
                  <p>{{ film_details?film_details.genre: ""}}</p>
                  <br />
                  <h5 class="w3-opacity"><b>Cast</b></h5>
                  <p>{{ film_details?film_details.cast: ""}}</p>
                  <hr />
                </div>
              </div>
            </div>

            <!-- <div class="w3-container w3-card w3-white">
              <h2 class="w3-text-grey w3-padding-16">
                <i class="fa fa-play-circle fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Trailer
              </h2>
              <div class="w3-container">
                <iframe width="100%" height="380" src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0">
                </iframe>
                <hr />
              </div>
            </div> -->
            <br />
            <div class="w3-container w3-card w3-white">
              <h2 class="w3-text-grey w3-padding-16">
                <i class="fa fa-play-circle fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Movie
              </h2>
              <div class="w3-container">
                <iframe width="100%" height="380" :src="film_details ? film_details.movie_url : ''">
                </iframe>
                <hr />
              </div>
            </div>

            <!-- End Right Column -->
          </div>

          <!-- End Grid -->
        </div>

        <!-- End Page Container -->
      </div>
    </div>

    <br />
  </div>
</template>

<script>
import API from '../../config/api';

export default {
  data() {
    return {
      film_details: null
    }
  },
  created() {
    this.getFilmInfo();
  },
  methods: {
    async getFilmInfo() {
      try {
        let response = await API.get('api/admin/view-movie', {
          params: {
            movie_id: this.$route.params.id
          }
        });
        this.film_details = response.data.movie;
        console.log(this.film_details);
      } catch (error) {
        console.log(error.response);
      }
    },
    async updateMovieStatus(status) {
      try {
        let response = await API.post('api/admin/change-movie-status', {
          movie_id: this.$route.params.id,
          status
        });
        alert(response.data.message);
        this.film_details = response.data.movie;
      } catch (error) {
        console.log(error.response);
      }
    }
  }
};
</script>

<style>
html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

.approvebtn {
  width: 110px;
  color: green;
  border-radius: 16px;
  border: 2px solid green;
  background-color: white;
  padding: 5px;
}

.approvebtn:hover {
  width: 110px;
  color: white;
  border-radius: 16px;
  border: 2px solid green;
  background-color: green;
  padding: 5px;
}

.rejectbtn {
  width: 110px;
  color: red;
  border-radius: 16px;
  border: 2px solid red;
  background-color: white;
  padding: 5px;
}

.rejectbtn:hover {
  width: 110px;
  color: white;
  border-radius: 16px;
  border: 2px solid red;
  background-color: red;
  padding: 5px;
}
</style>
