<template>
  <div class="upload-form-wrap">
    <div class="row" v-if="valid_token">
      <div class="col top-class-upload">
        <h4>Gorilla Film Upload Form</h4>

        <div>
          <label for="title">Upload Video*</label>
          <div class="input-group mb-3">
            <UploadVideos :base_url="base_url" :token="token" v-on:success="file_uploaded" />
          </div>
        </div>

        <div>
          <label for="title">Full Name*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="name" />
          </div>
        </div>

        <div>
          <label for="title">Relation To Film*</label>
          <div class="input-group mb-3">
            <select name="" id="" class="form-control" v-model="reltion">
              <option value="" selected disabled>Select Relation</option>
              <option value="Director">Director</option>
              <option value="Producer">Producer</option>
              <option value="Actor">Actor</option>
              <option value="Investor">Investor</option>
              <option value="Others">Other</option>
            </select>
          </div>
        </div>

        <div>
          <label for="title">Website*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="website" />
          </div>
        </div>

        <div>
          <label for="title">Company*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="company" />
          </div>
        </div>

        <div>
          <label for="title">Email Address* (this will not be published)</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="email" />
          </div>
        </div>

        <div>
          <label for="title">Phone Number* (this will not be published)</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="phone" />
          </div>
        </div>

        <div>
          <label for="title">Address* (this will not be published)</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="address" />
          </div>
        </div>

        <div style="display: none">
          <label for="title">Movie URL*</label>
          <div class="input-group mb-3">
            <input type="text" disabled class="form-control" v-model="movie_url" />
          </div>
        </div>

        <div>
          <label for="title">City*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="city" />
          </div>
        </div>

        <div>
          <label for="title">State*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="state" />
          </div>
        </div>

        <div>
          <label for="title">Country*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="country" />
          </div>
        </div>

        <div>
          <label for="title">Zip Code*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="zip" />
          </div>
        </div>

        <div>
          <label for="title">Title*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="film_name" />
          </div>
        </div>

        <div>
          <label for="title">Genre*</label>
          <div class="input-group mb-3">
            <select name="" id="" class="form-control" v-model="genre">
              <option value="" selected disabled>Select a genre</option>
              <option v-for="data in all_genres" :key="data.id" value="data.category">
                {{ data.category }}
              </option>
            </select>
          </div>
        </div>

        <div>
          <label for="title">Poster*</label>
          <div class="input-group mb-3">
            <input ref="file" type="file" class="form-control" accept="image/*" />
          </div>
        </div>

        <div>
          <label for="title">This Film Is Rated*</label>
          <div class="input-group mb-3">
            <select name="" id="" class="form-control" v-model="rated">
              <option value="" selected disabled>Select Film Rating</option>
              <option value="G">G</option>
              <option value="PG">PG</option>
              <option value="PG-13">PG-13</option>
              <option value="R">R</option>
            </select>
          </div>
        </div>

        <div>
          <label for="title">Director*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="director" />
          </div>
        </div>

        <div>
          <label for="title">Starring*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="cast" />
          </div>
        </div>

        <div>
          <label for="title">Release Date*</label>
          <div class="input-group mb-3">
            <input type="date" class="form-control" v-model="release_date" />
          </div>
        </div>

        <div>
          <label for="title">Running Time*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="run_time" />
          </div>
        </div>

        <div>
          <label for="title">Language*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="language" />
          </div>
        </div>

        <div>
          <label for="title">Key Words*</label>
          <div class="input-group mb-3">
            <textarea cols="30" rows="10" v-model="key_words" class="form-control"></textarea>
          </div>
        </div>

        <!-- <div>
          <label for="title">Type*</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="type" />
          </div>
        </div> -->

        <div>
          <label for="title">Synopsis*</label>
          <div class="input-group mb-3">
            <textarea cols="30" rows="10" v-model="synopsis" class="form-control"></textarea>
          </div>
        </div>

        <div>
          <label for="title">Message*</label>
          <div class="input-group mb-3">
            <textarea cols="30" rows="10" v-model="message" class="form-control"></textarea>
          </div>
        </div>
        <div>
          <router-link to="/terms">I agree to the terms and conditions</router-link>
          <input type="checkbox" v-model="terms" />
        </div>


        <div>
          <div class="input-group mb-3 justify-content-center">
            <button class="btn btn-lg btn-primary" :disabled="!submit_button || !terms" type="button" @click="upload">
              Upload video
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="success_message">
      <p>{{ success_message }}</p>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import axios from "axios";
import UploadVideos from "./UploadVideos";
export default {
  components: {
    UploadVideos,
  },
  data() {
    return {
      token: null,
      name: "",
      reltion: "",
      website: "",
      company: "",
      email: "",
      phone: "",
      address: "",
      movie_url: "",
      thumbnail: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      film_name: "",
      genre: "",
      director: "",
      cast: "",
      release_date: "",
      run_time: "",
      language: "",
      synopsis: "",
      message: "",
      valid_token: false,
      submit_button: false,
      base_url: "https://api.gorillafilm.com/api/",
      success_message: "",
      key_words: "",
      all_genres: [],
      rated: "",
      terms: false,
      terms_and_conditions: false,
    };
  },
  created() {
    this.pageload();
    this.getAllGenre();
  },
  methods: {
    async pageload() {
      try {
        this.token = this.$route?.params?.token ?? null;
        if (this.token) {
          const result = await axios.get(`${this.base_url}user/verify`, {
            headers: { Authorization: `Bearer ${this.token}` },
          });
          console.log(result);
          this.valid_token = true;
        }
      } catch (error) {
        console.log("Something went wrong. " + error);
      }
    },
    async upload() {
      try {
        this.token = this.$route?.params?.token ?? null;
        if (this.token) {
          let formdata = new FormData();

          formdata.append("name", this.name);
          formdata.append("reltion", this.reltion);
          formdata.append("website", this.website);
          formdata.append("company", this.company);
          formdata.append("email", this.email);
          formdata.append("phone", this.phone);
          formdata.append("address", this.address);
          formdata.append("movie_url", this.movie_url);

          formdata.append("thumbnail", this.thumbnail);
          if (this.$refs.file.files) {
            formdata.append("thumbnail", this.$refs.file.files[0]);
          }
          formdata.append("city", this.city);
          formdata.append("state", this.state);
          formdata.append("country", this.country);
          formdata.append("zip", this.zip);
          formdata.append("film_name", this.film_name);
          formdata.append("genre", this.genre);
          formdata.append("director", this.director);
          formdata.append("cast", this.cast);
          formdata.append("release_date", this.release_date);
          formdata.append("run_time", this.run_time);
          formdata.append("language", this.language);
          formdata.append("synopsis", this.synopsis);
          formdata.append("message", this.message);
          formdata.append("key_words", this.key_words);
          formdata.append("rated", this.rated);

          const result = await axios.post(
            `${this.base_url}user/add-video`,
            formdata,
            {
              headers: { Authorization: `Bearer ${this.token}` },
            }
          );
          console.log(result.data.message);
          this.success_message = result.data.message;
          this.valid_token = false;
        }
      } catch (error) {
        console.log("Something went wrong. " + error);
      }
    },

    async file_uploaded(data) {
      this.movie_url = data?.path;
      this.run_time = data.duration;
      this.submit_button = true;
      console.log(data);
    },

    async getAllGenre() {
      try {
        const result = await axios.get(`${this.base_url}all-genres-screaning`);
        console.log(result);
        this.all_genres = result.data.genres;
      } catch (error) {
        console.log("Something went wrong. " + error);
      }
    },
  },
};
</script>

<style>
.top-class-upload {
  padding-top: 50px;
  padding-right: 30px;
  padding-bottom: 50px;
  padding-left: 30px;
}

.upload-form-wrap {
  color: #000;
  font-size: 16px;
  background: rgb(214, 90, 40);
  background: linear-gradient(9deg, #ff9305 0%, #fe6d29 100%);
}

.upload-form-wrap h4 {
  font-size: 1.5rem;
  text-align: center;
}

.top-class-upload h4 {
  font-size: 2rem;
  font-weight: 700;
}

.upload-form-wrap label {
  color: #000;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.upload-form-wrap select,
.upload-form-wrap input {
  font-size: 1.125rem;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-weight: 500;
  color: #000;
}

.video-dropzone .dropzone-display {
  border: 3px dashed #000;
}

.uploading-video span {
  display: inline-block;
  font-size: 1.125rem;
}

input[type="file"] {
  /* Style the color of the message that says 'No file chosen' */
  color: #000;
}

input[type="file"]::-webkit-file-upload-button {
  background: #fe6d29;
  border: 2px solid #fe6d29;
  border-radius: 4px;
  margin-right: 1rem;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  padding: 5px 25px;
  text-transform: uppercase;
  transition: all 1s ease;
}

@media (max-width: 767px) {
  .video-dropzone {
    padding: 0;
    width: 100%;
    height: auto;
  }

  .video-dropzone h3 {
    font-size: 1.5rem;
    text-align: center;
  }

  .video-dropzone h4 {
    font-size: 1.125rem;
    text-align: center;
  }
}
</style>
