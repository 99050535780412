import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export const store = new Vuex.Store({

    //export default new Vuex.Store({
    state: {

        //baseurl: 'https://api-believe.staginganideos.com/api',
        baseurl: 'https://api.gorillafilm.com/',
        isLoading:false

    },
    getters: {},

    mutations: {

    },
    actions: {

    },
    modules: {
    }
})