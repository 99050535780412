import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../pages/login";
import Main from "../pages/main";
import Dashboard from "../pages/dashboard/dashboard";
import Filmlog from "../pages/filmlog/filmlog";
import FilmRequestLog from "../pages/filmlog/filmRequestLog";
import FilmlogDetail from "../pages/filmlog/filmlogDetail";
import Upload from "../pages/upload_portal/upload";
import Terms from "../pages/upload_portal/Terms";
import API from "../config/api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/",
    component: Main,
    children: [
      {
        path: "",
        component: Dashboard,
      },
      {
        path: "film-log",
        component: Filmlog,
      },
      {
        path: "film-request-log",
        component: FilmRequestLog,
      },
      {
        path: "film-log-detail/:id",
        component: FilmlogDetail,
      },
    ],
    beforeEnter: (to, from, next) => {
      API.get(`api/admin/verify`)
        .then(() => {
          // console.log(res);
          next();
        })
        .catch(() => next("/login"));
    }
  },
  {
    path: "/upload/:token",
    component: Upload,
  },
  {
    path: "/terms",
    component: Terms,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
