<template>
  <div class="container-fluid">
    <!-- Page Heading -->
    <h1 class="h3 mb-2 text-gray-800">Film Log</h1>

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
      <div class="card-header py-3"></div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Film Name</th>
                <th>Director Name</th>
                <th>Added On</th>
                <th>Email</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(data, index)  in film_log" :key="data.id">
                <td>{{ index+ 1 }}</td>
                <td>{{ data.film_name }}</td>
                <td>{{ data.director }}</td>
                <td>{{ data.created_at }}</td>
                <td>{{ data.email }}</td>
                <td>{{ data.status.toUpperCase() }}</td>
                <td>
                  <router-link class="collapse-item" :to="'film-log-detail/' + data.id"><i class="fa fa-eye"
                      style="color: black; cursor: pointer"></i></router-link>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '../../config/api';

export default {
  data() {
    return {
      film_log: null
    }
  },
  created() {
    this.getFilmLog()
  },
  methods: {
    async getFilmLog() {
      try {
        let response = await API.get('api/admin/film-log');
        this.film_log = response.data.movies;
      } catch (error) {
        console.log(error.response);
      }
    }
  }
};
</script>

<style>

</style>
