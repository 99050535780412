<template>
  <div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
              <div class="col-lg-6">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                  </div>
                  <form class="user">
                    <div class="form-group">
                      <input v-model="email" type="email" class="form-control form-control-user" id="exampleInputEmail"
                        aria-describedby="emailHelp" placeholder="Enter Email Address..." />
                    </div>
                    <div class="form-group">
                      <input v-model="password" type="password" class="form-control form-control-user"
                        id="exampleInputPassword" placeholder="Password" />
                    </div>
                    <button type="button" href="" @click="login()" class="btn btn-primary btn-user btn-block">
                      Login
                    </button>
                    <hr />
                  </form>
                  <hr />
                  <div class="text-center">
                    <button type="button" class="small btn btn-secondary btn-user" href="">
                      Forgot Password?
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '../config/api';

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      try {
        let response = await API.post('api/admin/login', {
          email: this.email,
          password: this.password
        });
        localStorage.setItem('token', response.data.token)
        this.$router.push("/");

      } catch (error) {
        alert(error?.response?.data?.message)
      }
      // console.log(this.email, this.password);
    },
    forgotpassword() {
      console.log(this.email, this.password);
    },
  },
};
</script>

<style>

</style>
