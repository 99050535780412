<template>
  <div class="upload-form-wrap">
    <div class="row">
      <div class="col top-class-upload">
        <div>
          <label for="title"><h2>Terms and conditions</h2></label>
          <div class="input-group mb-3">
            <ul>
              <li>
                All languages and genres of feature films, short films and
                documentaries are welcome.
              </li>
              <li>All filmmakers must be at least 18 years old.</li>
              <li>
                Gorilla Film Streaming delivery requirements:
                <ul>
                  <li>
                    1 VIDEO MASTER | 1080p with original frame rate in .MP4
                    format using the H.264 codec with stereo audio.
                  </li>
                  <li>
                    At the beginning and end of the film there must be at least
                    one frame of black no more than one second long.
                  </li>
                  <li>
                    There must be no watermarks, bugs, DVD logos, promotional
                    intros, release dates, websites, color bars, slates,
                    countdowns, advertisements, or MPAA ratings at the beginning
                    of the film.
                  </li>
                  <li>
                    Subtitles for foreign language films should only be burned
                    in with proper timing and no misspellings.
                  </li>
                  <li>Pornography and hate films will not be accepted.</li>
                  <li>
                    Filmmakers earn 0.10¢ (ten cents) per hour viewed. (In
                    comparison, Tubi and Prime pay out 0.06¢ per hour viewed).
                  </li>
                  <li>
                    Earnings and total watch time are calculated daily and can
                    be viewed on your dashboard. Payouts are calculated
                    quarterly and payment of earnings will be sent via wire
                    transfer or PayPal.
                  </li>
                  <li>
                    Person submitting film claims authority and has permission
                    to upload film to Gorilla Film Streaming. You will be
                    contacted via email when and if your film passes approval.
                    This process can take 2-3 days.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.top-class-upload {
  padding-top: 50px;
  padding-right: 30px;
  padding-bottom: 50px;
  padding-left: 30px;
}

.upload-form-wrap {
  color: #000;
  font-size: 16px;
  background: rgb(214, 90, 40);
  background: linear-gradient(9deg, #ff9305 0%, #fe6d29 100%);
}

.upload-form-wrap h4 {
  font-size: 1.5rem;
  text-align: center;
}

.top-class-upload h4 {
  font-size: 2rem;
  font-weight: 700;
}

.upload-form-wrap label {
  color: #000;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.upload-form-wrap select,
.upload-form-wrap input {
  font-size: 1.125rem;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-weight: 500;
  color: #000;
}

.video-dropzone .dropzone-display {
  border: 3px dashed #000;
}

.uploading-video span {
  display: inline-block;
  font-size: 1.125rem;
}

input[type="file"] {
  /* Style the color of the message that says 'No file chosen' */
  color: #000;
}

input[type="file"]::-webkit-file-upload-button {
  background: #fe6d29;
  border: 2px solid #fe6d29;
  border-radius: 4px;
  margin-right: 1rem;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  padding: 5px 25px;
  text-transform: uppercase;
  transition: all 1s ease;
}

@media (max-width: 767px) {
  .video-dropzone {
    padding: 0;
    width: 100%;
    height: auto;
  }

  .video-dropzone h3 {
    font-size: 1.5rem;
    text-align: center;
  }

  .video-dropzone h4 {
    font-size: 1.125rem;
    text-align: center;
  }
}
</style>
