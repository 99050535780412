<template>
  <!-- Sidebar -->
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      href="/"
    >
      <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas"></i>
      </div>
      <div class="sidebar-brand-text mx-3">Gorilla Films</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item">
      <router-link class="nav-link" to="/">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></router-link
      >
    </li>

    <!-- Heading -->
    <div class="sidebar-heading">Film log</div>
    <li class="nav-item">
      <router-link to="/film-log" class="nav-link" href="">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Film log</span></router-link
      >
    </li>
    <li class="nav-item">
      <router-link to="/film-request-log" class="nav-link" href="">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Film request log</span></router-link
      >
    </li>
    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />
  </ul>
  <!-- End of Sidebar -->
</template>

<script>
export default {};
</script>

<style></style>
