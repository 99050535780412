import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store/index.js"

Vue.config.productionTip = false;

console.log(store);
new Vue({
  render: h => h(App),
  router: router,
  store: store,

}).$mount('#app')
