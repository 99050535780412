<template>
  
  <div id="wrapper">
   
    <sidebar />

    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <Topbar />
        <router-view />
      </div>
      <!-- End of Main Content -->
      <!-- Footer -->
      <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright &copy; Gorilla Films 2022</span>
          </div>
        </div>
      </footer>
      <!-- End of Footer -->
    </div>
    <!-- End of Content Wrapper -->
  </div>
</template>

<script>
import sidebar from "../components/sidebar.vue";
import Topbar from "../components/topbar.vue";
export default {
  components: { sidebar, Topbar },
  mounted()
  {
    console.log(this.$store);
  }
};
</script>

<style></style>
